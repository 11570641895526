var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1750px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.layerClose()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(
            _vm._s(
              _vm.activityStep === 1
                ? _vm.$t("cp.CTRL020P180.001")
                : _vm.$t("cp.CTRL020P180.002")
            )
          ),
        ]),
        _c("div", { ref: "scroll", staticClass: "flex_box cp-flex_box" }, [
          _c("div", { staticClass: "content_box cp-parentnode mt10" }, [
            _c("div", { staticClass: "cp-popinfo" }, [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P180.003")))]),
                _c("span", [_vm._v(_vm._s(_vm.parentInfo.sfOpt.scenarioNm))]),
              ]),
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P180.004")))]),
                _c("span", [_vm._v(_vm._s(_vm.parentInfo.sfOpt.effectTo))]),
              ]),
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P180.005")))]),
                _c("span", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P180.006")))]),
              ]),
              _c("div"),
            ]),
          ]),
          _c("div", { staticClass: "content_box cp-parentnode" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL020P180.029"))),
            ]),
            _c("div", {
              staticStyle: { width: "100%", height: "452px" },
              attrs: { id: "realgrid1" },
            }),
          ]),
          _c("div", { staticClass: "content_box cp-parentnode" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL020P180.030"))),
            ]),
            _c("div", {
              staticStyle: { width: "100%", height: "452px" },
              attrs: { id: "realgrid2" },
            }),
          ]),
          _vm._m(0),
        ]),
        _c("div", { staticClass: "text_center mt10 cp-parentnode" }, [
          _c("div", { staticClass: "cp-pre-container" }, [
            _c("pre", { staticClass: "txt_desc cp-pre" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL020P180.007"))),
            ]),
          ]),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              class: { disabled: _vm.activityStep === 1 },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.previous()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cp.COMMON.012")))]
          ),
          _vm.activityStep === 1
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.next()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cp.COMMON.013")))]
              )
            : _vm.activityStep === 2
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.asyncAlert(_vm.$t("cp.CTRL020P180.008"))
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cp.COMMON.011")))]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.layerClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cp.COMMON.007")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_box cp-parentnode" }, [
      _c("div", {
        staticStyle: { width: "100%", height: "560px" },
        attrs: { id: "realgrid3" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }