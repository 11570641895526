<template>
  <div class="popup_wrap" style="width:1750px;">
    <button class="layer_close" @click.prevent="layerClose()">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ activityStep === 1 ? $t('cp.CTRL020P180.001') : $t('cp.CTRL020P180.002') }}</h1>
      <div class="flex_box cp-flex_box" ref="scroll">
        <div class="content_box cp-parentnode mt10">
          <div class="cp-popinfo">
            <div>
              <span>{{ $t('cp.CTRL020P180.003') }}</span>
              <span>{{ parentInfo.sfOpt.scenarioNm }}</span>
            </div>
            <div>
              <span>{{ $t('cp.CTRL020P180.004') }}</span>
              <span>{{ parentInfo.sfOpt.effectTo }}</span>
            </div>
            <div>
              <span>{{ $t('cp.CTRL020P180.005') }}</span>
              <span>{{ $t('cp.CTRL020P180.006') }}</span>
            </div>
            <div></div>
          </div>
        </div>
        <div class="content_box cp-parentnode">
          <h2 class="content_title">{{ $t('cp.CTRL020P180.029') }}</h2>
          <div id="realgrid1" style="width: 100%; height: 452px" />
        </div>
        <div class="content_box cp-parentnode">
          <h2 class="content_title">{{ $t('cp.CTRL020P180.030') }}</h2>
          <div id="realgrid2" style="width: 100%; height: 452px" />
        </div>
        <div class="content_box cp-parentnode">
          <div id="realgrid3" style="width: 100%; height: 560px" />
        </div>
      </div>
      <div class="text_center mt10 cp-parentnode">
        <div class="cp-pre-container">
          <pre class="txt_desc cp-pre">{{ $t('cp.CTRL020P180.007') }}</pre>
        </div>
        <a class="button blue lg" href="#" @click.prevent="previous()" :class="{ disabled: activityStep === 1 }">{{ $t('cp.COMMON.012') }}</a>
        <a v-if="activityStep === 1" class="button blue lg" href="#" @click.prevent="next()">{{ $t('cp.COMMON.013') }}</a>
        <a v-else-if="activityStep === 2" class="button blue lg" href="#" @click.prevent="asyncAlert($t('cp.CTRL020P180.008'))">{{ $t('cp.COMMON.011') }}</a>
        <a class="button blue lg" href="#" @click.prevent="layerClose()">{{ $t('cp.COMMON.007') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import scenarioTargetSet from '@/api/rest/cp/scenarioTargetSet'
  import { GridView, LocalDataProvider } from 'realgrid'

  let [gridView1, gridView2, gridView3] = [GridView, GridView, GridView]
  let [provider1, provider2, provider3] = [LocalDataProvider, LocalDataProvider, LocalDataProvider]

  const fields1 = [
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' },
    { fieldName: 'promotionOwner', dataType: 'text' },
    { fieldName: 'lastModifiedDate', dataType: 'text' }
  ]

  const fields2 = [
    { fieldName: 'transferYn', dataType: 'text' },
    { fieldName: 'promotionNm', dataType: 'text' },
    { fieldName: 'customIdx', dataType: 'number' },
    { fieldName: 'teuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'revTeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'cm1TeuTgt', dataType: 'number', subType: 'uint' }
  ]

  const fields3 = [
    { fieldName: 'promotionNm', dataType: 'text' },
    { fieldName: 'transferYn', dataType: 'text' },
    { fieldName: 'customIdx', dataType: 'number' },
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' },
    { fieldName: 'bkgShipper', dataType: 'text' },
    { fieldName: 'bkgShipperNm', dataType: 'text' },
    { fieldName: 'salUnoBs', dataType: 'text' },
    { fieldName: 'teuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'revTeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'cm1TeuTgt', dataType: 'number', subType: 'uint' }
  ]

  const columns1 = [
    { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { text: app.$t('cp.CTRL020P180.009') }, width: 80, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { text: app.$t('cp.CTRL020P180.010') }, width: 80, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { text: app.$t('cp.CTRL020P180.011') }, width: 80, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { text: app.$t('cp.CTRL020P180.012') }, width: 80, editable: false },
    { name: 'promotionOwner', fieldName: 'promotionOwner', header: { text: app.$t('cp.CTRL020P180.013') }, width: 100, editable: false },
    { name: 'lastModifiedDate', fieldName: 'lastModifiedDate', header: { text: app.$t('cp.CTRL020P180.014') }, width: 100, editable: false }
  ]

  const columns2 = [
    { name: 'transferYn', fieldName: 'transferYn', type: 'text', visible: false },
    { name: 'promotionNm', fieldName: 'promotionNm', type: 'text', header: { text: app.$t('cp.CTRL020P180.003') }, width: 150, editable: false },
    { name: 'customIdx', fieldName: 'customIdx', type: 'number', visible: false },
    { name: 'teuTgt', fieldName: 'teuTgt', header: { text: app.$t('cp.CTRL020P180.015'), styleName: 'cp-tgt-header' }, width: 100, numberFormat: '0', editable: true, editor: { type: 'number', positiveOnly: true, integerOnly: true }, styleName: 'cp-tgt-column' },
    { name: 'revTeuTgt', fieldName: 'revTeuTgt', header: { text: app.$t('cp.CTRL020P180.016'), styleName: 'cp-tgt-header' }, width: 100, numberFormat: '0', editable: true, editor: { type: 'number', positiveOnly: true, integerOnly: true }, styleName: 'cp-tgt-column' },
    { name: 'cm1TeuTgt', fieldName: 'cm1TeuTgt', header: { text: app.$t('cp.CTRL020P180.017'), styleName: 'cp-tgt-header' }, width: 100, numberFormat: '0', editable: true, editor: { type: 'number', positiveOnly: true, integerOnly: true }, styleName: 'cp-tgt-column' }
  ]

  const columns3 = [
    { name: 'promotionNm', fieldName: 'promotionNm', type: 'text', header: { text: app.$t('cp.CTRL020P180.003') }, width: 180, editable: false, renderer: { showTooltip: true } },
    { name: 'transferYn', fieldName: 'transferYn', type: 'text', visible: false },
    { name: 'customIdx', fieldName: 'customIdx', type: 'number', visible: true },
    { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { text: app.$t('cp.CTRL020P180.009') }, width: 50, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { text: app.$t('cp.CTRL020P180.010') }, width: 50, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { text: app.$t('cp.CTRL020P180.011') }, width: 50, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { text: app.$t('cp.CTRL020P180.012') }, width: 50, editable: false },
    { name: 'bkgShipper', fieldName: 'bkgShipper', type: 'text', header: { text: app.$t('cp.CTRL020P180.018') }, width: 80, editable: false, renderer: { showTooltip: true } },
    { name: 'bkgShipperNm', fieldName: 'bkgShipperNm', type: 'text', header: { text: app.$t('cp.CTRL020P180.019') }, width: 180, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'salUnoBs', fieldName: 'SalUnoBs', header: { text: app.$t('cp.CTRL020P180.020') }, width: 70, editable: false },
    { name: 'teuTgt', fieldName: 'teuTgt', header: { text: app.$t('cp.CTRL020P180.015') }, width: 100, numberFormat: '0', editable: false },
    { name: 'revTeuTgt', fieldName: 'revTeuTgt', header: { text: app.$t('cp.CTRL020P180.016') }, width: 100, numberFormat: '0', editable: false },
    { name: 'cm1TeuTgt', fieldName: 'cm1TeuTgt', header: { text: app.$t('cp.CTRL020P180.017') }, width: 100, numberFormat: '0', editable: false }
  ]

  const layout2 = [
    {
      name: 'activityTgt',
      direction: 'horizontal',
      items: [
        'teuTgt',
        'revTeuTgt',
        'cm1TeuTgt'
      ],
      header: {
        text: app.$t('cp.CTRL020P180.022'),
        styleName: 'cp-tgt-header'
      }
    }
  ]

  const layout3 = [
    'promotionNm',
    'porCtr',
    'porPlc',
    'dlyCtr',
    'dlyPlc',
    'bkgShipper',
    'bkgShipperNm',
    'salUnoBs',
    {
      name: 'activityTgt',
      direction: 'horizontal',
      items: [
        'teuTgt',
        'revTeuTgt',
        'cm1TeuTgt'
      ],
      header: {
        text: app.$t('cp.CTRL020P180.022')
      }
    }
  ]

  export default {
    name: 'SfPromotionActivityPop',
    components: {
    },
    props: {
      parentInfo: {
        type: Object,
        default: function () {
          return {
          }
        }
      }
    },
    data: function () {
      return {
        activityStep: 1,
        filteringReferKey: []
      }
    },
    mounted () {
      const $vm = this

      provider1 = new LocalDataProvider(true)
      gridView1 = new GridView('realgrid1')
      gridView1.setDataSource(provider1)
      gridView1.header.heights = [45]
      gridView1.header.showTooltip = false
      gridView1.footers.visible = false
      gridView1.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'rows',
        columnMovable: false
      })
      gridView1.setStateBar({
        visible: false
      })
      gridView1.setRowIndicator({
        visible: false
      })
      gridView1.setCheckBar({
        visible: false
      })
      gridView1.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView1.setFilteringOptions({
        enabled: false,
        handleVisibility: 'none'
      })
      gridView1.onCurrentRowChanged = (_gridView, _oldRow, newRow) => {
        gridView2.commit()
        $vm.selectVaildPromotion(newRow)
      }

      provider2 = new LocalDataProvider(true)
      gridView2 = new GridView('realgrid2')
      gridView2.setDataSource(provider2)
      gridView2.header.heights = [22, 23]
      gridView2.header.showTooltip = false
      gridView2.footers.visible = false
      gridView2.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView2.setStateBar({
        visible: true
      })
      gridView2.setRowIndicator({
        visible: false
      })
      gridView2.setCheckBar({
        visible: false
      })
      gridView2.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView2.setFilteringOptions({
        enabled: false,
        commitBeforeFiltering: true,
        handleVisibility: 'none'
      })
      gridView2.setEditOptions({
        commitByCell: true
      })
      gridView2.setPasteOptions({
        checkReadOnly: true,
        enableAppend: false
      })
      gridView2.onEditCommit = (_grid, _index, _oldValue, newValue) => {
        if (['', null, undefined].includes(newValue)) {
          return false
        }
      }
      gridView2.onCellPasting = (_grid, _index, value) => {
        if (['', null, undefined].includes(value) || Number.isNaN(value)) {
          return false
        }
      }

      provider3 = new LocalDataProvider(true)
      gridView3 = new GridView('realgrid3')
      gridView3.setDataSource(provider3)
      gridView3.header.heights = [22, 23]
      gridView3.header.showTooltip = false
      gridView3.footers.visible = false
      gridView3.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView3.setStateBar({
        visible: false
      })
      gridView3.setRowIndicator({
        visible: false
      })
      gridView3.setCheckBar({
        visible: false
      })
      gridView3.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView3.setFilteringOptions({
        enabled: false,
        commitBeforeFiltering: true,
        handleVisibility: 'none'
      })
      gridView3.setRowStyleCallback((_grid, item) => {
        const idx = item.dataRow
        const rowData = provider3.getJsonRow(idx)
        return rowData.transferYn === 'N' ? 'cp-n-row' : ''
      })

      // 그리드 정보 전처리
      $vm.setInfo()

      // 그리드 필드 생성
      provider1.setFields(fields1)
      gridView1.setColumns(columns1)
      provider2.setFields([...$vm.parentInfo.sfOpt.fields, ...fields2])
      gridView2.setColumns([...$vm.parentInfo.sfOpt.columns, ...columns2])
      provider3.setFields(fields3)
      gridView3.setColumns(columns3)

      // 컬럼 순서 및 레이아웃 조정
      const refColumnIdx = $vm.parentInfo.sfOpt.columnLayout.map(elem => elem.column).indexOf('salUnoBs') + 1
      gridView2.setColumnLayout([...$vm.parentInfo.sfOpt.columnLayout.slice(0, refColumnIdx), ...layout2, ...$vm.parentInfo.sfOpt.columnLayout.slice(refColumnIdx)])
      gridView3.setColumnLayout(layout3)

      // 데이터 처리 및 그리드 생성
      $vm.setGridList()
    },
    methods: {
      setInfo () {
        const renameMapper = {
          bkgShipper: this.$t('cp.CTRL020P180.018'),
          bkgShipperNm: this.$t('cp.CTRL020P180.019'),
          salUnoBs: this.$t('cp.CTRL020P180.020')
        }

        for (const key of ['porCtr', 'porPlc', 'dlyCtr', 'dlyPlc']) {
          if (this.parentInfo.sfOpt.groupKeyCheck[`${key}Yn`] === 'Y') {
            this.filteringReferKey.push(key)
          }
        }

        for (const row of this.parentInfo.sfOpt.columns) {
          if (Object.keys(renameMapper).includes(row.name)) {
            row.visible = true
            row.header.text = renameMapper[row.name]
            row.header.template = renameMapper[row.name]
          }
        }
      },
      getCorridorString (row) {
        return JSON.stringify(this.filteringReferKey.map(key => row[key]))
      },
      async setGridList () {
        const baseGridList = this.parentInfo.sfOpt.gridList
        const promotionList = await scenarioTargetSet.scenarioPromotionCheck(baseGridList).then(response => {
          return response.data
        }).catch(err => {
          console.error(err)
        })

        const validCorridorList = promotionList.map(row => this.getCorridorString(row))
        const salesList = [...new Set(baseGridList.map(row => row.salUnoBs))]
        const promiseList = []
        for (const salUnoBs of salesList) {
          const promise = scenarioTargetSet.salesforceUserCheck(salUnoBs).then(response => {
            const data = {
              userId: salUnoBs,
              isValid: response.data
            }
            return data
          })
          promiseList.push(promise)
        }

        const salesAuthList = await Promise.allSettled(promiseList).then(response => {
          const data = response.map(elem => elem.value)
          return data.filter(elem => elem.isValid === 'OK').map(elem => elem.userId)
        }).catch(e => {
          console.error('promise-all', e)
        })

        const activityList = baseGridList.map(row => {
          const addedRow = {
            promotionNm: null,
            transferYn: null,
            customIdx: 0,
            teuTgt: 0,
            revTeuTgt: 0,
            cm1TeuTgt: 0
          }
          const isInvalidUser = !salesAuthList.includes(row.salUnoBs)
          const isNotMatchCorridor = !validCorridorList.includes(this.getCorridorString(row))

          switch (true) {
            case isNotMatchCorridor: {
              addedRow.promotionNm = this.$t('cp.CTRL020P180.023')
              addedRow.transferYn = 'N'
              addedRow.customIdx = -1
              break
            }
            case isInvalidUser: {
              addedRow.promotionNm = this.$t('cp.CTRL020P180.031')
              addedRow.transferYn = 'N'
              addedRow.customIdx = -2
              break
            }
            default: {
              addedRow.promotionNm = this.parentInfo.sfOpt.scenarioNm
              addedRow.transferYn = 'Y'
              addedRow.customIdx = promotionList.map(row => this.getCorridorString(row)).reverse().indexOf(this.getCorridorString(row))
              break
            }
          }
          return Object.assign({}, row, addedRow)
        })

        const validActivityList = activityList.filter(row => salesAuthList.includes(row.salUnoBs))
        const validActivityCorridorList = [...new Set(validActivityList.map(row => this.getCorridorString(row)))]
        const validPromotionList = promotionList.filter(row => {
          const corridor = this.getCorridorString(row)
          return validActivityCorridorList.includes(corridor)
        })

        provider1.setRows(validPromotionList)
        provider2.setRows(activityList)

        // https://forum.realgrid.com/topics/LZnQJcYJNigiLsocG
        gridView1.onDataLoadComplated = () => {
          gridView1.resetCurrent()
          gridView1.setSelection({ style: 'rows', startRow: 0, endRow: 0 })
        }
      },
      updateGridList () {
        const gridList2 = provider2.getJsonRows()
        const gridList3 = gridList2.map(row => ({
          promotionNm: row.promotionNm,
          transferYn: row.transferYn,
          customIdx: row.customIdx,
          porCtr: row.porCtr,
          porPlc: row.porPlc,
          dlyCtr: row.dlyCtr,
          dlyPlc: row.dlyPlc,
          bkgShipper: row.bkgShipper,
          bkgShipperNm: row.bkgShipperNm,
          salUnoBs: row.salUnoBs,
          teuTgt: row.teuTgt,
          revTeuTgt: row.revTeuTgt,
          cm1TeuTgt: row.cm1TeuTgt
        })).sort((a, b) => b.customIdx - a.customIdx)

        provider3.setRows(gridList3)
      },
      selectVaildPromotion (idx) {
        const rowData = provider1.getJsonRow(idx)
        const filteringInfo = this.filteringReferKey.reduce((acc, key) => {
          const criteria = `(value = "${rowData[key]}")`
          acc[key] = criteria
          return acc
        }, {})

        for (const columnName of this.filteringReferKey) {
          if (filteringInfo[columnName].includes('undefined')) {
            continue
          }
          const column = gridView2.columnByName(columnName)
          column.setFilters([{
            name: columnName,
            criteria: filteringInfo[columnName],
            text: columnName,
            active: true
          }])
        }

        gridView2.columnByName('transferYn').setFilters([{
          name: 'transferYn',
          criteria: '(value = "Y")',
          text: 'transferYn',
          active: true
        }])
      },
      previous () {
        this.activityStep = 1
        this.scroll()
      },
      next () {
        this.activityStep = 2
        gridView2.commit()
        this.scroll()
        this.updateGridList()
      },
      scroll () {
        const container = this.$refs.scroll
        const scrollOpt = {
          top: this.activityStep === 1 ? 0 : container.scrollHeight,
          behavior: 'smooth'
        }
        container.scrollTo(scrollOpt)
      },
      async runActivity () {
        const activityList = gridView3.getJsonRows(0, -1).map(row => ({
          scenarioCd: this.parentInfo.sfOpt.groupKeyCheck.scenarioCd,
          transferYn: row.transferYn,
          porCtr: row.porCtr,
          porPlc: row.porPlc,
          dlyCtr: row.dlyCtr,
          dlyPlc: row.dlyPlc,
          bkgShipper: row.bkgShipper,
          bkgShipperNm: row.bkgShipperNm,
          salUnoBs: row.salUnoBs,
          teuTgt: row.teuTgt,
          revTeuTgt: row.revTeuTgt,
          cm1TeuTgt: row.cm1TeuTgt
        }))

        await scenarioTargetSet.salesforcePromotionActivity(activityList).then(response => {
          switch (response.data) {
            case 'OK': {
              this.openAlert(this.$t('cp.CTRL020P180.024'))
              break
            }
            case 'NO': {
              this.openAlert(this.$t('cp.CTRL020P180.025'))
              break
            }
            case 'ING': {
              this.openAlert(this.$t('cp.CTRL020P180.026'))
              break
            }
            default: {
              const sfErrorContainer = document.createElement('div')
              sfErrorContainer.setAttribute('class', 'cp-inner-container cp-scroll cp-scroll--auto mt10')
              const txt = document.createTextNode(response.data)
              sfErrorContainer.appendChild(txt)
              const msg = `${this.$t('cp.CTRL020P180.027')}<br>${sfErrorContainer.outerHTML}`
              this.openAlert(msg)
              break
            }
          }
        }).catch(err => {
          console.log(err)
        })
      },
      layerClose () {
        this.$emit('close')
        $('.popup_dim').fadeOut()
        $('body,html').removeAttr('style')
        return false
      },
      openAlert (msg) {
        let obj = {
          alertType: 'simple',
          onClose: this.closeFun,
          customCloseBtnClass: 'button blue lg simplert-test1',
          customCloseBtnText: this.$t('cp.COMMON.005'),
          useIcon: false,
          customClass: 'simple_alert',
          message: msg,
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      },
      async asyncAlert (msg) {
        let obj = {
          alertType: 'normal',
          showXclose: true,
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$t('cp.COMMON.007'),
          customConfirmBtnText: this.$t('cp.COMMON.005'),
          customConfirmBtnClass: 'button blue lg',
          useConfirmBtn: true,
          title: this.$t('cp.CTRL020P180.028'),
          message: msg,
          type: 'info'
        }
        gridView2.commit()
        if (await this.$ekmtcCommon.asyncAlert(obj)) {
          this.runActivity()
        }
      }
    }
  }
</script>
<style>
  .cp-popinfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .cp-popinfo > div > span {
    display: inline-block;
  }

  .cp-popinfo > div > span:first-child {
    color: #075bb9;
    font-weight: 500;
    margin-right: 30px;
  }

  .cp-flex_box {
    gap: 10px;
    flex-wrap: wrap;
    height: 606px;
    overflow-y: hidden;
  }

  .cp-flex_box .content_box {
    margin-top: 0;
  }

  .cp-flex_box > .cp-parentnode:nth-child(1) {
    flex-basis: 100%;
  }

  .cp-flex_box > .cp-parentnode:nth-child(2) {
    flex-basis: 40%;
  }

  .cp-flex_box > .cp-parentnode:nth-child(3) {
    flex-basis: calc(60% - 10px);
  }

  .cp-flex_box > .cp-parentnode:nth-child(4) {
    margin-top: 0 !important;
    flex-basis: 100%;
  }

  .cp-n-row {
    color: #aaaaaa !important;
    background-color: #f5f5f5 !important;
  }

  .cp-pre-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    position: absolute;
    left: 0;
  }

  .cp-parentnode .txt_desc {
    margin-top: 0;
  }

  .cp-pre {
    font-family: 'Noto Sans KR', sans-serif;
  }

  .cp-parentnode > a.button {
    width: 55px;
  }
</style>
